import config from './config';
import { register } from '../register-components';

import PolyfillService from '../../src/polyfill-service/mojo.js';
import UiElements from '../../src/ui-elements/2.1.0/mojo.js';
import Favorites from '../../src/favorites/2.1.3/mojo.js';
import Travelexplorer from '../../src/travelexplorer/1.0.0/mojo.js';
import HotelOfferCards from '../../src/hotel-offer-cards/1.6.0/mojo.js';
import UpsellTeaser from '../../src/upsell-teaser/1.2.7/mojo.js';
import HotelBestOffer from '../../src/hotel-best-offer/1.0.0/mojo.js';
import TravelMap from '../../src/travel-map/1.0.6/mojo.js';
import Campaign from '../../src/campaign/2.1.4/mojo-starter.js';
import CampaignVisuals from '../../src/campaign-visuals/1.1.3/mojo.js';
import Coupons from '../../src/coupons/1.0.9/mojo.js';
import IbeLinkBuilder from '../../src/ibe-link-builder/mojo.js';
import Shopfinder from '../../src/shopfinder/current/at/mojo.js';
//import TuiPoiHotelMap from '../../src/poi-hotel-map/1.2.0/mojo';
import TuiHotelMap from "../../src/hotel-map/1.0.0/mojo";
import smartBanner from '../../src/smartbanner/mojo.js';
import TuiSearchResultMap from '../../src/search-result-map/1.2.0/mojo';
import UnsupportedBrowserModal from '../../src/unsupported-browser-modal/mojo';
import HotelCards from '../../src/hotelcards-at/1.4.0/mojo.js';
import MapsConsent from '../../src/maps-consent/1.0.0/mojo';
import TravelInformation from '../../src/travel-information-at/1.0.0/mojo';
import Login from '../../src/login-at/1.9.4/mojo.js';
import Qualtrics from '../../src/qualtrics/1.1.0/mojo';

const components = {
    PolyfillService,
    UiElements,
    HotelOfferCards,
    HotelBestOffer,
    UpsellTeaser,
    Favorites,
    Travelexplorer,
    Campaign,
    CampaignVisuals,
    Coupons,
    TravelMap,
    IbeLinkBuilder,
    Shopfinder,
    //TuiPoiHotelMap,
    TuiHotelMap,
    smartBanner,
    TuiSearchResultMap,
    UnsupportedBrowserModal,
    HotelCards,
    MapsConsent,
    TravelInformation,
    Login,
    Qualtrics
};

const prodConfig = {
    ...config,
    getApiUrl: () => 'https://api.tui.com/api/',
    getStage: () => 'prod'
}

register(components, prodConfig);
